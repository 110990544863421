$(function () {

    /**
     * Home > Hero : Animation de background au survol du mot clé
     *
     * Home
     */

    var breakLineHeight = document.getElementById("photo-random-height").offsetTop;

    $("#photo-random-event").hover(
        function () {

            $("#photo-random").removeClass("photo-random--hidden");
            $("#photo-random").css("height", breakLineHeight);

            $("#photo-random-hero").addClass("is-white");
            $("#photo-random-menu").addClass("is-white");

            $("#photo-random-logo-white").removeClass("is-hidden");
            $("#photo-random-logo-black").addClass("is-hidden");

        }, function () {

            $("#photo-random").addClass("photo-random--hidden");

            $("#photo-random-hero").removeClass("is-white");
            $("#photo-random-menu").removeClass("is-white");

            $("#photo-random-logo-black").removeClass("is-hidden");
            $("#photo-random-logo-white").addClass("is-hidden");

        }
    );

});